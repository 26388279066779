<div class="col-lg-12 mb-2" data-offset="0">
  <div class="card default shadow border border-1" [class.border-lightgray]="job?.schedules?.length > 0"
    [class.border-danger]="job?.schedules?.length < 1">
    <div class="card-header pb-1">
      <div class="d-flex">
        <span class="me-3 section-header">
          SCHEDULES
        </span>
        <span class="ms-auto">
          <button *ngIf="!job?.invoice" class="btn btn-xs" (click)="scheduleAddNew()" title="Add" type="button" [disabled]="!canAddNew()">
            <em class="fas fa-plus-circle"></em> Add New
          </button>
          <div *ngIf="job?.invoice">
            <i class="fas fa-lock"></i>
          </div>
        </span>
      </div>
    </div>
    <div class="card-body pt-0" id="list-item">
      <table class="table table-md table-bordered">
        <thead class="table-light">
          <tr class="header text-center">
            <th class="text-start"><i class="fas fa-clock me-2 text-dark"></i>SCHEDULES</th>
            <ng-container *ngIf="!is_CE_TA_IM_PD()">
              <th class="text-start">NDIS</th>
              <th class="text-start">Work Type</th>
              <th class="text-start">Task</th>
            </ng-container>
            <th class="text-start">Employees</th>
            <th *ngIf="job?.isGroupActivity">Group</th>
            <th width="5%">Kms</th>
            <th>Hours</th>
            <!-- <th>Charge</th> -->
            <th width="7%">Job</th>
            <th width="7%" class="text-center">Action
              <!-- <button *ngIf="!job?.invoice" class="btn btn-xs" (click)="scheduleAddNew()"
                    title="Add">
                    <em class="fas fa-plus-circle"></em> Add New
                  </button> -->
              <!-- <div *ngIf="job?.invoice">
                    <i class="fas fa-lock"></i>
                  </div> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!job?.schedules?.length">
            <td [attr.colspan]="!is_CE_TA_IM_PD() ? 8 : 5" class="no-data">No Data</td>
          </tr>
          <ng-container *ngFor="let item of job?.schedules">
            <tr *ngIf="!item.editMode">
              <td>
                <span class="d-flex">
                  {{item.startDate}}
                  <span class="ms-1 ">{{item.startTimeDisplay}} ~ {{item.endTimeDisplay}}</span>
                  <span class="ms-auto" *ngIf="!item.isClinicNote">
                    <span *ngIf="item.timeBadge === 'DAYTIME'" class="badge bg-secondary badge-min">DAYTIME</span>
                    <span *ngIf="item.timeBadge === 'EVENING'" class="badge bg-warning badge-min">EVENING</span>
                    <span *ngIf="item.timeBadge === 'NIGHT'" class="badge bg-danger badge-min">NIGHT</span>
                    <span *ngIf="item.timeBadge === 'SATURDAY'" class="badge bg-green badge-min">SATURDAY</span>
                    <span *ngIf="item.timeBadge === 'SUNDAY'" class="badge bg-green badge-min">SUNDAY</span>
                    <span *ngIf="item.isSleepover" class="badge bg-green badge-min">SLEEPOVER</span>

                    <span *ngIf="job?.isGroupActivity" class="ms-1 badge bg-purple badge-min">GROUP</span>
                  </span>

                  <span class="ms-auto badge bg-black badge-min hoverable" *ngIf="item.isClinicNote"
                    (click)="openEditClinicNoteModal(item.note)">
                    NOTE
                  </span>
                </span>
              </td>
              <ng-container *ngIf="!is_CE_TA_IM_PD()">
                <td class="text-center">
                  <span *ngIf="item.isNDISJob">
                    <em class="fa-solid fa-check"></em>
                  </span>
                </td>
                <td>{{item.jobType?.name || 'N/A'}}</td>
                <td>

                  {{item.workTypeItem?.name || item.workTypeItem2?.name || 'N/A'}}
                  <i *ngIf="item.workTypeItem?.code == 'SS'" class="fa-solid fa-street-view"></i>
                  <span *ngIf="item.isMobile && !item.isTravelTimeChargeable" class="ms-1">
                    <i class="fa-solid fa-car"></i>
                  </span>
                  <span *ngIf="item.isTravelTimeChargeable" class="">
                    + <i class="fa-solid fa-car text-success"></i>
                  </span>
                  <span *ngIf="item.workTypeItem?.name == 'Phone Call' || item.workTypeItem2?.name == 'Phone Call'"
                    class="ms-1">
                    <i class="fa-solid fa-phone-flip text-info"></i>
                  </span>
                  <span *ngIf="item.workTypeItem?.name == 'Resources'" class="ms-1">
                    <i class="fa-solid fa-file-lines"></i>
                  </span>

                </td>
              </ng-container>
              <td class="text-start">
                <div *ngFor="let employee of item.employees">
                  <span class="clickable-link" (click)="goToStaffDetail(employee)">{{employee.fullName || 'N/A'}}
                  </span>
                  <span class="text-muted text-sm">
                    ({{employee.awardLineItemFind?.employmentType || 'N/A'}})
                    <!-- <span *ngIf="item.employmentType == 'CAS'">L{{employee.awardLineItemFind?.level}} P{{employee.awardLineItemFind?.payPoint}}</span> -->
                  </span>
                  <span *ngFor="let timeEntry of employee.timeEntries">
                    <span *ngIf="timeEntry.jobScheduleId === item.id">
                      <span *ngIf="timeEntry.lateStart !== null" [class.bg-secondary]="timeEntry.lateStartLevel === 1"
                        [class.bg-warning]="timeEntry.lateStartLevel === 2"
                        [class.bg-danger]="timeEntry.lateStartLevel === 3" class="badge"
                        [tooltip]="'Late Start ' + timeEntry.lateStart + ' minutes'">LS</span>&nbsp;

                      <span [class.text-danger]="timeEntry.lateStartLevel !== 0">{{timeEntry.start |
                        customdate:'hh:mm A'}}</span>
                      ~ <span [class.text-danger]="timeEntry.earlyFinishLevel !== 0">{{timeEntry.end |
                        customdate:'hh:mm A'}}</span>&nbsp;
                      <span *ngIf="timeEntry.earlyFinishLevel !== null"
                        [class.bg-secondary]="timeEntry.earlyFinishLevel === 1"
                        [class.bg-warning]="timeEntry.earlyFinishLevel === 2"
                        [class.bg-danger]="timeEntry.earlyFinishLevel === 3" class="badge"
                        [tooltip]="'Early Finish ' + timeEntry.earlyFinish + ' minutes'">EF</span>
                    </span>
                  </span>
                </div>
              </td>
              <td *ngIf="job?.isGroupActivity">
                {{getGroupById(item.grouping) || '--'}}
              </td>
              <td class="text-center">
                <em *ngIf="item.isKmsPayable" class="fa-solid fa-check" title="Kms"></em>
              </td>
              <td class="text-end">{{item.hours || 0 | number : '1.0-2'}}</td>
              <!-- <td class="text-end">{{item.hours || 0 | number : '1.0-2'}}</td> -->
              <td class="text-center">
                <!-- <em *ngIf="item.isChargeable" class="fa-solid fa-check" title="Chargeable"></em> -->
                <span *ngIf="job?.jobClass === 'J' && !job?.invoice && !item.chargeToJobId">
                  <i class="pointer fas fa-search-dollar text-dark" (click)="openModal(jobChargeAssistModal, item)"
                    title="Job Charge Assist&trade;"></i>
                  <!-- <span *ngIf="!item.isLocked"> | </span> -->
                </span>
                <span *ngIf="item.chargeToJobId">
                  ***
                </span>
              </td>
              <td class="text-center text-nowrap">
                <span *ngIf="!item.isLocked">
                  <span class="pointer" (click)="scheduleGotoEditMode(item)">Edit</span> |
                  <!-- <span class="pointer" (click)="scheduleDelete(item)">Del</span> | -->
                  <span class="dropdown">
                    <button class="btn btn-xs btn-gray-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">

                    </button>
                    <ul class="dropdown-menu">
                      <li *ngIf="item.workTypeItem?.code != 'SS'"><a class="dropdown-item clickable"
                          (click)="openModal(shadowShiftModal, item)">Add Shadow Shift</a></li>
                      <li><a class="dropdown-item clickable" (click)="scheduleDelete(item)">Delete Schedule</a></li>
                    </ul>
                  </span>
                  &nbsp;<span class="fas fa-spinner fa-pulse" *ngIf="item.inProgress"></span>
                </span>
                <span *ngIf="item.isLocked">
                  <i class="fas fa-lock ms-1" (click)="scheduleUnlock(item)" title="Unlock"></i>
                </span>
              </td>
            </tr>
            <tr *ngIf="!item.editMode && item.description">
              <td colspan="9" class="ms-3"><em>{{item.description}}</em></td>
            </tr>
            <tr *ngIf="item.editMode" class="edit-mode bt-edit">
              <td class="d-grid ms-1">
                <span class="text-xs7">Date:</span>
                <input type="text" placeholder="dd/mm/yyyy" class="form-control" style="width: 165px" bsDatepicker
                  [bsConfig]="{dateInputFormat:  'DD/MM/YYYY', containerClass: 'theme-blue'}"
                  [(ngModel)]="item.startDate" [ngModelOptions]="{standalone: true}" (bsValueChange)="onStartDateSelected($event)">

                <div class="d-flex">
                  <div class="d-grid">
                    <span class="text-xs7 mt-1">From:</span>
                    <input class="form-control time-input" type="text" [(ngModel)]="item.startTime" mask="Hh:m0"
                      [dropSpecialCharacters]="false" (input)="onTimeChange(item)" placeholder="hh:mm"
                      [class.err]="!item.isStartValid" />
                  </div>
                  <div class="d-grid ms-1">
                    <span class="text-xs7 mt-1">To:</span>
                    <input class="form-control time-input" type="text" [(ngModel)]="item.endTime" mask="Hh:m0"
                      [dropSpecialCharacters]="false" (input)="onTimeChange(item)" placeholder="hh:mm"
                      [class.err]="!item.isEndValid" />
                  </div>
                  <div class="d-grid ms-1 mt-auto mb-s1" *ngIf="showIsMobileBox(item)">
                    <div class="btn-group" role="group" aria-label="is mobile group">
                      <input type="radio" class="btn-check me-1" name="isMobileOptions" id="radio1" autocomplete="off"
                        [value]="false" [(ngModel)]="item.isMobile">
                      <label class="btn btn-xs btn-outline-primary" for="radio1">Clinic</label>

                      <input type="radio" class="btn-check" name="isMobileOptions" id="radio2" autocomplete="off"
                        [value]="true" [(ngModel)]="item.isMobile">
                      <label class="btn btn-xs btn-outline-primary" for="radio2">Mobile</label>

                    </div>
                  </div>
                </div>
                <div class="d-flex" *ngIf="item.isMobile">
                  <div class="d-grid">
                    <span class="text-xs7 mt-1">Leave:</span>
                    <input class="form-control time-input" type="text" [(ngModel)]="item.officeLeaveTime" mask="Hh:m0"
                      [dropSpecialCharacters]="false" placeholder="Leave" />
                  </div>
                  <div class="d-grid ms-1">
                    <span class="text-xs7 mt-1">Return:</span>
                    <input class="form-control time-input" type="text" [(ngModel)]="item.officeReturnTime" mask="Hh:m0"
                      [dropSpecialCharacters]="false" placeholder="Return" />
                  </div>
                  <div class="d-grid ms-1">
                    <span class="text-xs7 mt-1">Charge Travel Time?</span>
                    <div class="checkbox c-checkbox mt-2 w-100">
                      <label>
                        <input type="checkbox" id="isTravelTimeChargeable" [(ngModel)]="item.isTravelTimeChargeable">
                        <span class="fa fa-check"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-grid text-xs7 mt-1" *ngIf="item.isTravelTimeChargeable && item.isMobile">
                  <div>Charge To: 15 mins</div>
                  <div>Charge From: 15 mins</div>
                </div>
              </td>
              <ng-container *ngIf="!is_CE_TA_IM_PD()">
                <td colspan="3">
                  <div class="d-grid me-3">
                    <div class="mb-2 flex">
                      <!-- <input type="checkbox" id="isNIDSJob" [(ngModel)]="item.isNDISJob"
                          (change)="onIsNDISJobChange(item)">
                        <label class="ms-1 form-check-label" for="isNIDSJob">Is this an NDIS Job ?</label> -->
                      <div class="btn-group" role="group" aria-label="is NDIS group">

                        <input type="radio" class="btn-check me-1" name="isNDISOptions" id="radioNDISJob1" autocomplete="off"
                           [value]="true" [(ngModel)]="item.isNDISJob" (change)="onIsNDISJobChange(item)">
                        <label class="btn btn-xs btn-outline-primary" for="radioNDISJob1">NDIS</label>

                        <!-- <input type="radio" class="btn-check" name="isNDISOptions" id="radioNDISJob2" autocomplete="off"
                          [value]="false" [(ngModel)]="item.isNDISJob" (change)="onIsNDISJobChange(item)">
                        <label class="btn btn-xs btn-outline-primary" for="radioNDISJob2">Medicare</label> -->

                        <input type="radio" class="btn-check" name="isNDISOptions" id="radioNDISJob3" autocomplete="off"
                          [value]="false" [(ngModel)]="item.isNDISJob" (change)="onIsNDISJobChange(item)">
                        <label class="btn btn-xs btn-outline-primary" for="radioNDISJob3">Private</label>
                      </div>
                    </div>
                    <span class="text-xs7 mt-1">Work Type:</span>
                    <select class="form-select form-select-sm" (change)="onJobTypeChange(item)"
                      [(ngModel)]="item.jobTypeId" [class.err]="!item.jobTypeId">
                      <option value="">Select Work Type ...</option>
                      <option *ngFor="let jobType of jobTypes" [value]="jobType.id">
                        {{jobType.name}}
                      </option>
                    </select>
                    <span class="text-xs7 mt-1">Work Task:</span>
                    <select class="form-select form-select-sm" [(ngModel)]="item.workType">
                      <option value="">Select Work Task ...</option>
                      <option *ngFor="let workType of workTypes" [value]="workType.code">
                        {{workType.name}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>
              <div *ngIf="is_IM()">
                <span class="text-xs7 mt-1">Work Type:</span>
                <select class="form-select form-select-sm" (change)="onJobTypeChange(item)"
                  [(ngModel)]="item.jobTypeId" [class.err]="!item.jobTypeId">
                  <option value="">Select Work Type ...</option>
                  <option *ngFor="let jobType of officeJobTypes" [value]="jobType.id">
                    {{jobType.name}}
                  </option>
                </select>

               
                <div class="checkbox c-checkbox mt-2 w-100">
                  <label>
                    <input type="checkbox" id="isHrsPayable" [(ngModel)]="item.isHrsPayable">
                    <span class="fa fa-check"></span>Hours Payable
                  </label>
                </div>
                

              </div>
              <td class="align-top" style="max-width:180px" colspan="5">
                <div class="me-3">
                  <span class="text-xs7">Employees:</span>
                  <ng-select #select [items]="employees" bindLabel="fullName" [multiple]="true" [hideSelected]="true"
                    [(ngModel)]="item.employees" placeholder="Select employee(s)">
                  </ng-select>
                </div>

                <div class="checkbox c-checkbox mt-3 w-100" *ngIf="showSleepOver(item)">
                  <label>
                    <input type="checkbox" id="isSleepover" [(ngModel)]="item.isSleepover">
                    <span class="fa fa-check"></span>Sleepover
                  </label>
                </div>

                <div *ngIf="job?.isGroupActivity">
                  <span class="text-xs7 mt-1">Grouping:</span>
                  <select class="form-select form-select-sm" 
                  [(ngModel)]="item.grouping" [class.err]="!item.grouping">
                  <option value="">Select Grouping...</option>
                  <option *ngFor="let group of groupings" [value]="group.id">
                    {{group.name}}
                  </option>
                </select>
                </div>

                <div class="checkbox c-checkbox mt-2 w-100" *ngIf="job?.jobClass === 'J'">
                  <label>
                    <input type="checkbox" id="isChargeable" [(ngModel)]="item.isChargeable">
                    <span class="fa fa-check"></span>Specific Charge
                  </label>
                </div>

              </td>
              <!-- <td></td>
                  <td class="" colspan="3">
                   
                  </td> -->
              <!-- <td class="text-center">
                    <div class="checkbox c-checkbox mt-2 w-100">
                      <label>
                        <input type="checkbox" id="isKmsPayable" [(ngModel)]="item.isKmsPayable">
                        <span class="fa fa-check"></span>Kms
                      </label>
                    </div>
                  </td> -->

                 

              <!-- <td>
                   
                  </td> -->
              <!-- <td> </td> -->
            </tr>
            <tr *ngIf="!item.isNDISJob && job?.client?.isNDISClient && job?.client?.id"
              [class.edit-mode]="item.editMode">
              <!-- <td></td> -->
              <td colspan="9">
                <i class="fa-solid fa-circle-exclamation text-danger"></i>
                This is an NDIS Client but the schedule is set as non NDIS. Please confirm.
              </td>
            </tr>
            <tr
              *ngIf="!item.isClinicNote && item.isNDISJob && !job.isGroupActivity && !job?.client?.isNDISClient && job?.client?.id"
              [class.edit-mode]="item.editMode">
              <td colspan="9" class="">
                <i class="fa-solid fa-circle-exclamation text-danger"></i>
                This is not an NDIS Client but the schedule is set as NDIS. Please confirm.
              </td>
            </tr>
            <!-- <tr *ngIf="job?.isGroupActivity" [class.edit-mode]="item.editMode">
                  <td colspan="9" class="">
                    ** Group Activity Job
                  </td>
                </tr> -->
            <tr *ngIf="item.editMode" class="edit-mode bb-edit">
              <td [attr.colspan]="!is_CE_TA_IM_PD() ? 5 : 3" class="">

                <div class="mb-2 mt-2 ms-1 grid-flex">
                  <div class="text-xs7">Admin Notes:</div>
                  <input type="text" class="form-control" [(ngModel)]="item.description" placeholder="Notes"
                    id="inputDescription">
                </div>
              </td>
              <td [attr.colspan]="!is_CE_TA_IM_PD() ? 4 : 3" class="buttons-actions">
                <div class="d-flex me-4 justify-content-end">
                  <span>
                    <button class="btn btn-gray btn-sm me-2" (click)="cancelSchedule(item)" title="Cancel"
                      type="button">
                      Cancel
                    </button>
                    <button class="btn btn-primary btn-sm" type="button" [disabled]="!isScheduleValid(item)"
                      (click)="onSaveScheduleLineItem(item)" title="Save">
                      <em class="fas fa-save"></em> Save
                    </button>
                    &nbsp;<span class="fas fa-spinner fa-pulse" *ngIf="item.inProgress"></span>
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr class="table-borderless text-bold header">
            <td [attr.colspan]="!is_CE_TA_IM_PD() ? 4 : 1"></td>
            <td *ngIf="job?.isGroupActivity"></td>
            <td colspan="2" class="text-end">
              <div>TOTAL Hours</div>
            </td>
            <td class="text-end">
              <div>{{job?.summary?.totalScheduledHours || 0 | number : '1.0-2'}}</div>
            </td>
            <!-- <td class="text-end">
                  <div>{{job?.summary?.totalScheduledHours || 0 | number : '1.0-2'}}</div>
                </td> -->
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="job?.client?.isCare247">
        This is a Care 24/7 client. Please ensure that the schedule covers the entire 24 hours.
        <div>
          <span
            [class.text-danger]="job?.summary?.totalScheduledHours < 24"
            [class.text-success]="job?.summary?.totalScheduledHours >= 24">
            Total Scheduled Hours: {{job?.summary?.totalScheduledHours || 0 | number : '1.0-2'}}
          </span>
        </div>
      </div>     
      <div *ngIf="job.isShadowShift">
        <div class="mt-2">
          <span class="text-danger">This job has Shadow Shifts. Please ensure they dont exceed the approved hours and that adequate supervision is in place.</span>
        </div>     
      </div>
      <div *ngIf="notesActive?.length">
        <div class="mt-2">
          <span class="text-danger">This client has active alert notes. Please review and action as required.</span>
        </div>
        <div *ngFor="let note of notesActive">
          <div class="mt-2 text-secondary">
              {{note.detail | removeHtmlTags}}                           
          </div>
      </div>
  </div>
</div>

<!-- JOB CHARGE ASSIST MODAL -->
<div class="modal fade" bsModal #jobChargeAssistModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{backdrop: 'static'}" (onHide)="onModalHide($event)">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Job Charge Assist&trade;</h4>
        <button type="button" class="btn-close" aria-label="btn-close"
          (click)="closeModal(jobChargeAssistModal)"></button>
      </div>
      <div class="modal-body">
        <div class="mb-1" *ngIf="selectedScheduleItem">
          <table class="table table-borderless">
            <tr *ngIf="job?.isGroupActivity">
              <td><b>Clients:</b></td>
              <td>
                <ul style="list-style-type: none">
                  <li *ngFor="let item of job?.groupClientBookings">
                    <input type="checkbox" [id]="item.client.id" [(ngModel)]="item.checked">&nbsp;<label
                      [for]="item.client.id">{{item.client?.fullName}}</label>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><b>Job Type:</b></td>
              <td>{{selectedScheduleItem.jobType?.name || 'N/A'}}</td>
            </tr>
            <tr>
              <td><b>Work Type:</b></td>
              <td>{{selectedScheduleItem.workTypeItem?.name || 'N/A'}}</td>
            </tr>
            <tr>
              <td><b>Time:</b></td>
              <td>
                {{selectedScheduleItem.startDate}}
                <span class="ms-1">
                  {{selectedScheduleItem.startTimeDisplay}} ~ {{selectedScheduleItem.endTimeDisplay}}&nbsp;
                  <span *ngIf="selectedScheduleItem.timeBadge === 'DAYTIME'" class="badge bg-secondary">DAYTIME</span>
                  <span *ngIf="selectedScheduleItem.timeBadge === 'EVENING'" class="badge bg-warning">EVENING</span>
                  <span *ngIf="selectedScheduleItem.timeBadge === 'NIGHT'" class="badge bg-danger">NIGHT</span>
                  <span *ngIf="selectedScheduleItem.timeBadge === 'SATURDAY'" class="badge bg-green">SATURDAY</span>
                  <span *ngIf="selectedScheduleItem.timeBadge === 'SUNDAY'" class="badge bg-green">SUNDAY</span>
                </span>
              </td>
            </tr>
            <tr>
              <td><b>Hours:</b></td>
              <td>{{selectedScheduleItem.hours || 0 | number : '1.0-2'}}</td>
            </tr>
          </table>

          <!-- <div class="mt-4" *ngIf="selectedScheduleItem.jobType?.name.includes('Medicare')">
            <label>Add Medicare Rebate to Charges?</label>
            <input type="checkbox" class="ms-1" checked [(ngModel)]="!!selectedScheduleItem.isMedicareRebate">            
          </div> -->

          <div class="mt-4">
            <button type="button" class="btn btn-primary"
              (click)="jobChargeAssistAddNewCharge(jobChargeAssistModal, selectedScheduleItem)">Add New Charge</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary"
          (click)="cancelAccountStatus(accountStatusModal)">Close</button> -->
      </div>
    </div>
  </div>
</div>

<!-- Shadow Shift Add Modal -->
<div class="modal fade" bsModal #shadowShiftModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{backdrop: 'static'}" (onHide)="onModalHide($event)">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Shadow Shift</h4>
        <button type="button" class="btn-close" aria-label="btn-close" (click)="closeModal(shadowShiftModal)"></button>
      </div>
      <div class="modal-body">
        <div class="input-group mb-1 baseline" *ngIf="selectedScheduleItem">
          <table class="table table-borderless">

            <tr>
              <td><b>Job Type:</b></td>
              <td>{{selectedScheduleItem.jobType?.name || 'N/A'}}</td>
            </tr>

            <tr>
              <td><b>Employees:</b></td>
              <td>
                <ng-select #select [items]="employees" bindLabel="fullName" [multiple]="true" [hideSelected]="true"
                  [(ngModel)]="selectedScheduleItem.selectedEmployees" placeholder="Select employee(s)">
                </ng-select>
              </td>
            </tr>

          </table>
          <button type="button" class="btn btn-primary"
            (click)="createShadowShift(shadowShiftModal, selectedScheduleItem)">Add New Shadow Shift</button>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<app-edit-clinic-note [model]="selectedNote" #clinicNoteModal></app-edit-clinic-note>